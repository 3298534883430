@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/gtwalsheim-regular-webfont.woff2") format("woff2"),
    url("../fonts/gtwalsheim-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/gtwalsheim-medium-webfont.woff2") format("woff2"),
    url("../fonts/gtwalsheim-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GT Walsheim";
  src: url("../fonts/gtwalsheim-bold-webfont.woff2") format("woff2"),
    url("../fonts/gtwalsheim-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

.swiper-slide {
  height: auto !important;
}

.boutique-back-btn a {
  color: hsla(199, 95%, 15%, 1);
  text-decoration: none;
}

.boutique-back-btn a:hover {
  color: hsla(199, 95%, 15%, 1);
  text-decoration: underline;
}
